import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Row, Col } from 'reactstrap'

import ArticleAboutModal from './article-about-modal'
import {
  ArticleWrapper,
  ArticleContainer,
  ArticlePersonWrapper,
  ArticlePersonPhotoColWrapper,
  ArticlePersonPhotoWrapper,
  ArticlePersonAboutColWrapper,
  ArticlePersonDescColWrapper,
  ArticlePersonDescWrapper,
} from '../elements'

const Article = (props) => {
  const data = useStaticQuery(graphql`
    query {
      ArticlePerson1: file(
        relativePath: { eq: "parent/article1/person1.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 250) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      Article1Image1: file(
        relativePath: { eq: "parent/article1/ariticle1-image1.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      Article1Image2: file(
        relativePath: { eq: "parent/article1/ariticle1-image2.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      Article1Image3: file(
        relativePath: { eq: "parent/article1/ariticle1-image3.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <ArticleWrapper>
      <ArticleContainer>
        <ArticlePersonWrapper>
          <Row>
            <ArticlePersonPhotoColWrapper>
              <ArticlePersonPhotoWrapper>
                <Img
                  alt="Martyna Filipak - psycholog"
                  fluid={data.ArticlePerson1.childImageSharp.fluid}
                  className="articlePersonImage"
                />
              </ArticlePersonPhotoWrapper>
            </ArticlePersonPhotoColWrapper>
          </Row>
          <Row>
            <ArticlePersonAboutColWrapper>
              <div className="personInfo">
                <span className="personName">Martyna Filipak</span>{' '}
                <span className="personPosition">psycholog</span>
                <br />
                <ArticleAboutModal>
                  Jestem psychologiem, autorką bloga mama-psycholog.pl oraz mamą
                  czterolatka. Na{'\u00A0'}co dzień wspieram dzieci i{'\u00A0'}
                  ich rodziców w{'\u00A0'}rozwojowych i{'\u00A0'}codziennych
                  wyzwaniach. W{'\u00A0'}pracy bliskie są{'\u00A0'}mi idee NVC,
                  rodzicielstwa bliskości i{'\u00A0'}self-regulation.
                </ArticleAboutModal>
              </div>
            </ArticlePersonAboutColWrapper>
            <ArticlePersonDescColWrapper>
              <p>
                Jestem psychologiem, autorką bloga mama-psycholog.pl oraz mamą
                czterolatka.
                <br />
                Wspieram dzieci i{'\u00A0'}ich rodziców w{'\u00A0'}rozwojowych i
                {'\u00A0'}codziennych wyzwaniach.
                <br />W pracy bliskie są{'\u00A0'}mi{'\u00A0'}idee NVC,
                rodzicielstwa bliskości i{'\u00A0'}self-regulation.{' '}
              </p>
            </ArticlePersonDescColWrapper>
          </Row>
        </ArticlePersonWrapper>
        <ArticlePersonDescWrapper>
          <Row>
            <Col xs={12}>
              <p>
                Kiedy myślimy o{'\u00A0'}rozwoju dziecka, zazwyczaj w{'\u00A0'}
                pierwszej kolejności myślimy o{'\u00A0'}nabywaniu tzw. szkolnej
                wiedzy i{'\u00A0'}podstawowych umiejętności, takich jak
                mówienie, czytanie, pisanie czy liczenie. Coraz więcej mówi się
                ostatnio o{'\u00A0'}rozwoju emocjonalnym i{'\u00A0'}coraz więcej
                rodziców pochyla się także nad tym obszarem. Pośród wszystkich
                tych kompetencji edukacja finansowa wydaje się być dość
                zaniedbanym obszarem.
                <br />
                Tymczasem edukacja finansowa to{'\u00A0'}bardzo duży i{'\u00A0'}
                istotny obszar nabywania wiedzy o{'\u00A0'}otaczającym świecie i
                {'\u00A0'}koniecznych do{'\u00A0'}jej wykorzystania kompetencji.
                Na{'\u00A0'}tyle istotny, że{'\u00A0'}na{'\u00A0'}co{'\u00A0'}
                dzień przenika wszystkie wymienione wyżej obszary. Wszak
                umiejętności mówienia, pisania i{'\u00A0'}liczenia
                wykorzystujemy podczas zarządzania finansami. Jednocześnie
                pieniądze i{'\u00A0'}związane z{'\u00A0'}nimi tematy wywołują w
                {'\u00A0'}nas sporą dawkę, często silnych i{'\u00A0'}skrajnych,
                emocji.
                <br />
                Od{'\u00A0'}pieniędzy w{'\u00A0'}dużym stopniu zależy też sporo
                aspektów naszego życia: komfort, opieka zdrowotna, rozwój, pasje
                i{'\u00A0'}marzenia. Dlatego warto, abyśmy – podobnie jak uczymy
                nasze dzieci pisania, czytania, czy historii – dbali o{'\u00A0'}
                rozwój ich kompetencji zarządzania finansami. Tylko jak to
                {'\u00A0'}zrobić? Jak sprawić, aby nasze dziecko potrafiło
                rozsądnie zarządzać pieniędzmi? Jak budować odpowiedzialną
                postawę w{'\u00A0'}tym obszarze?
              </p>
            </Col>
            <Col xs={12}>
              <h2>Rola naśladowania wzorców</h2>
            </Col>
            <Col xs={12} lg={6}>
              <p>
                Pierwszym i{'\u00A0'}najważniejszym wzorcem zarządzania
                finansami są{'\u00A0'}rodzice lub najbliżsi opiekunowie. Nie mam
                tu{'\u00A0'}jednak na{'\u00A0'}myśli, że{'\u00A0'}dziecko
                wzoruje się na{'\u00A0'}tym, co{'\u00A0'}słyszy od{'\u00A0'}
                rodziców na{'\u00A0'}temat pieniędzy. Ważniejszym przekazem
                odbieranym przez dziecko jest postępowanie rodziców. Tym
                bardziej, że{'\u00A0'}niejednokrotnie między tym, co{'\u00A0'}
                rodzice mówią, a{'\u00A0'}tym, co{'\u00A0'}robią, zachodzi pewna
                rozbieżność. Dlatego warto zastanowić się, jakie wzorce przekaże
                dzieciom nasza postawa finansowa, jakie potomek będzie chłonąć z
                {'\u00A0'}niej wartości.
                <br />
                Czy sami potrafimy oszczędzać, czy tylko mówimy, że{'\u00A0'}tak
                trzeba? Planujemy budżet i{'\u00A0'}trzymamy się tego planu czy
                raczej spontanicznie wydajemy fundusze? Inwestujemy czy
                gromadzimy pieniądze? Posiadamy oszczędności na{'\u00A0'}wypadek
                niespodziewanych wydatków i{'\u00A0'}zdarzeń czy raczej
                zakładamy, że{'\u00A0'}jakoś to{'\u00A0'}będzie? Wszystkie te
                {'\u00A0'}aspekty składają się na{'\u00A0'}postawę finansową,
                którą przekażemy swoim dzieciom.
              </p>
            </Col>
            <Col xs={12} lg={6}>
              <Img
                fluid={data.Article1Image1.childImageSharp.fluid}
                className="articleImage"
              />
            </Col>
            <Col xs={12}>
              <h2>Ponoszenie konsekwencji</h2>
            </Col>
            <Col xs={12}>
              <p>
                W{'\u00A0'}żadnym aspekcie rozwoju zdrowego dziecka nie polecam
                stosowania nagród i{'\u00A0'}kar. Jednocześnie jednak zachęcam
                rodziców do{'\u00A0'}tego, aby pozwalali dzieciom odczuwać
                naturalne konsekwencje ich postępowania. Podobnie jest w
                {'\u00A0'}obszarze finansowym. Nie musimy nagradzać dziecka za
                {'\u00A0'}oszczędzanie, bo{'\u00A0'}oszczędzona kwota i
                {'\u00A0'}możliwości, jakie ze{'\u00A0'}sobą niesie, są
                {'\u00A0'}nagrodą samą w{'\u00A0'}sobie. Nie powinniśmy też
                nakładać kary na{'\u00A0'}dziecko za{'\u00A0'}szybkie i
                {'\u00A0'}nierozsądne wydanie swojego kieszonkowego. Brak
                pieniędzy aż{'\u00A0'}do{'\u00A0'}czasu następnej „wypłaty”
                będzie dla niego wystarczająco dotkliwą konsekwencją.
                <br />
                Jeśli jednak ta{'\u00A0'}naturalna nauka ma{'\u00A0'}prawidłowo
                zadziałać, nie powinniśmy jej przeszkadzać. Dziecko uczy się
                świata przede wszystkim przez własną eksplorację i{'\u00A0'}
                doświadczanie. I{'\u00A0'}zarówno przyjemne, jak i{'\u00A0'}
                nieprzyjemne wydarzenia są{'\u00A0'}ich ważną częścią. Dlatego
                najlepszym prezentem, jaki może otrzymać dziecko w{'\u00A0'}
                takich sytuacjach od{'\u00A0'}rodziców jest pozwolenie na
                {'\u00A0'}tę swobodną naukę.
                <br />
                Oczywiście rodzice mogą służyć dziecku radą, kiedy tego
                potrzebuje, czy też wspierać w{'\u00A0'}trudniejszych
                sytuacjach. Jednocześnie jednak powinni pozwolić dziecku na
                {'\u00A0'}doświadczanie wszystkich konsekwencji zarządzania
                finansami. Możemy tłumaczyć dzieciom nawet sto razy, że
                {'\u00A0'}warto planować wydatki i{'\u00A0'}oszczędzać, ale
                najlepiej zapamięta tę{'\u00A0'}naukę, doświadczając frustracji,
                kiedy nie będzie mogło sobie pozwolić na{'\u00A0'}pożądane
                przyjemności w{'\u00A0'}wyniku własnych decyzji.
              </p>
            </Col>
            <Col xs={12}>
              <div className="imgWrapper imgCenter">
                <Img
                  fluid={data.Article1Image2.childImageSharp.fluid}
                  className="articleImage"
                />
              </div>
            </Col>
            <Col xs={12}>
              <h2>Budowanie doświadczenia</h2>
            </Col>
            <Col xs={12}>
              <p>
                Poświęćmy jednak więcej uwagi doświadczeniu i{'\u00A0'}jego roli
                w{'\u00A0'}nabywaniu kompetencji zarządzania finansami. Właśnie
                za{'\u00A0'}pomocą doświadczenia i{'\u00A0'}praktyki rozwijamy
                różnego rodzaju umiejętności. Nie inaczej jest w{'\u00A0'}
                rozwoju samodzielności i{'\u00A0'}odpowiedzialności finansowej.
                <br />
                Aby dziecko miało szansę rozwinąć kompetencje w{'\u00A0'}
                zakresie zarządzania pieniędzmi, musi mieć z{'\u00A0'}nimi
                styczność. Wartościowe w{'\u00A0'}tej nauce będą więc możliwości
                obserwowania rodziców w{'\u00A0'}sytuacjach finansowych, takich
                jak: zarabianie, kupowanie, planowanie wydatków, oszczędzanie.
                <br />
                Jednocześnie jednak warto zadbać o{'\u00A0'}to, aby dziecko
                miało szansę posiadania własnych pieniędzy i{'\u00A0'}
                zarządzania nimi. Dzięki temu będzie miało możliwość realnego
                doświadczenia sytuacji i{'\u00A0'}emocji związanych z{'\u00A0'}
                finansami. Będzie samodzielnie odkrywać korzyści i{'\u00A0'}
                straty wynikające z{'\u00A0'}decyzji finansowych.
                <br />
                Finanse przestaną być dla dziecka czymś zupełnie nowym i
                {'\u00A0'}obcym. Dzięki temu zarządzanie nimi nie będzie
                wzbudzać niepotrzebnego dodatkowego stresu (wynikającego z
                {'\u00A0'}braku doświadczenia) w{'\u00A0'}przyszłości. Dlatego
                warto, aby dziecko otrzymywało kieszonkowe, które będzie
                stanowić jego własny kapitał do{'\u00A0'}samodzielnego
                zarządzania.
                <br />
                Starsze dzieci (w wieku szkolnym) warto też zacząć zaznajamiać z
                {'\u00A0'}produktami i{'\u00A0'}usługami bankowymi: kartą
                płatniczą, internetowymi przelewami i{'\u00A0'}aplikacjami
                bankowymi. Pieniądz, którego nie widać, jest dla dziecka
                początkowo zupełną abstrakcją. Tymczasem to{'\u00A0'}właśnie w
                {'\u00A0'}ten sposób odbywa się aktualnie ogrom operacji
                finansowych w{'\u00A0'}naszym codziennym życiu. Płacimy
                bezdotykowo, robimy zakupy przez internet czy też płacimy
                rachunki w{'\u00A0'}biegu za{'\u00A0'}pomocą aplikacji
                bankowych. Warto, aby nasze dzieci znały takie możliwości oraz
                rozumiały, że{'\u00A0'}wirtualny pieniądz także wymaga
                racjonalnego zarządzania. Tym bardziej, że{'\u00A0'}nie widzimy
                naocznie jego wydawania, więc początkowo odczuwamy je{'\u00A0'}
                lżej niż wydawanie gotówki, której możemy dotknąć.{' '}
              </p>
            </Col>
            <Col xs={12} lg={6} className="marginTop30">
              <Img
                fluid={data.Article1Image3.childImageSharp.fluid}
                className="articleImage"
              />
            </Col>
            <Col xs={12} lg={6} className="marginTop30">
              <h2 className="noMarginTop">
                Postawa odpowiedzialności finansowej
              </h2>
              <p>
                Na{'\u00A0'}odpowiedzialną postawę finansową składa się wiele
                czynników. Niektóre pozostaną poza kontrolą rodziców, na
                {'\u00A0'}inne zaś mają realny i{'\u00A0'}znaczący wpływ. Mają
                szansę zadecydować, jaki przykład zarządzania pieniędzmi dadzą
                swoim dzieciom. Mogą tworzyć okazje do{'\u00A0'}podejmowania
                finansowych decyzji i{'\u00A0'}ponoszenia ich konsekwencji. I
                {'\u00A0'}choć nie są{'\u00A0'}w{'\u00A0'}stanie wpłynąć na
                {'\u00A0'}wszystko, to{'\u00A0'}właśnie ich wsparcie i{'\u00A0'}
                przewodnictwo pomogą ich dzieciom stworzyć solidny fundament dla
                przyszłej odpowiedzialności i{'\u00A0'}samodzielności
                finansowej.
              </p>
            </Col>
          </Row>
        </ArticlePersonDescWrapper>
      </ArticleContainer>
    </ArticleWrapper>
  )
}

export default Article
