import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import ArticleHeader from '../../components/parent/article-header'
import Article from '../../components/parent/article'
import UsefulTools from '../../components/parent/useful-tools'

const IndexPage = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      ArticleHeaderImage1: file(
        relativePath: { eq: "parent/article-header1.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1110) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      Tool1: file(relativePath: { eq: "parent/tools/gry.png" }) {
        childImageSharp {
          fluid(maxWidth: 250) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      Tool2: file(relativePath: { eq: "parent/tools/krzyzowki.png" }) {
        childImageSharp {
          fluid(maxWidth: 250) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      Tool3: file(relativePath: { eq: "parent/tools/kolorowanka.png" }) {
        childImageSharp {
          fluid(maxWidth: 250) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <Layout location={location}>
      <SEO
        title="Jak budować postawę odpowiedzialności finansowej?"
        description="Okiem psychologa o tym, jak ważne są przykłady dorosłych, konsekwencja i styczność z finansami już w młodym wieku, by dziecko w przyszłości dobrze gospodarowało swoimi pieniędzmi."
        linkImage={data.ArticleHeaderImage1.childImageSharp.fluid.src}
      />

      <ArticleHeader
        image={data.ArticleHeaderImage1.childImageSharp.fluid}
        date="12.04.2021"
        title={
          <>
            Jak budować postawę
            <br />
            odpowiedzialności
            <br />
            finansowej?
          </>
        }
        desc={
          <p>
            Okiem psychologa o{'\u00A0'}tym, jak ważne
            <br />
            są przykłady dorosłych, konsekwencja
            <br />i styczność z{'\u00A0'}finansami już w{'\u00A0'}młodym
            <br />
            wieku, by{'\u00A0'}dziecko w{'\u00A0'}przyszłości dobrze
            <br />
            gospodarowało swoimi pieniędzmi.
          </p>
        }
        linkPrev="/strefa-rodzica/baza-wiedzy"
        linkNext="/strefa-rodzica/baza-wiedzy#narzedzia"
      />
      <Article />
      <UsefulTools
        items={[
          {
            title: 'Gry',
            subtitle: 'Zabawa w skojarzenia DO CZEGO TO PASUJE?',
            image: data.Tool1,
            link: '/parent/Zabawa w skojarzenia_do%20czego%20to%20pasuje.pdf',
          },
          {
            title: 'Gry',
            subtitle: 'Finansowe kalambury',
            image: data.Tool1,
            link: '/parent/Finansowe%20kalambury.pdf',
          },
          {
            title: 'Krzyżówki',
            subtitle: 'Krzyżówka 1',
            image: data.Tool2,
            link: '/parent/Krzyzowka%201.pdf',
          },
          {
            title: 'Kolorowanki i rebusy',
            subtitle: 'Rebusy',
            image: data.Tool3,
            link: '/parent/Rebusy.pdf',
          },
        ]}
      />
    </Layout>
  )
}

export default IndexPage
